/**
 * scriptタグの挿入
 * @param {String} src: 読み込むスクリプトのURL
 * @param {Boolean} [async=true]: 非同期ON | OFF
 * @param {HTMLElement} [elmTarget]: insertBeforeのターゲット
 * @param {Function} [callback]: コールバック関数
 * @returns
 */
export default function importScript(src, ...args) {
	if(!src || typeof src !== "string") {
		throw new TypeError("arguments[0] is required and must be string.");
	}
	
	// ** create DOM. **
	const elmScript = document.createElement("script");
	elmScript.src = src;
	
	// callback
	if(typeof args.slice(-1)[0] === "function") {
		const callback = args.pop();
		elmScript.onload = function() {
			callback(null, this);
		};
		
		elmScript.onerror = function() {
			callback(true, this);
		};
	}
	
	// async ... default: true
	let async = args[0];
	(typeof async === "boolean") && args.shift();
	(async === false) || (elmScript.async = 1);
	
	// append to DOM tree
	let elmTarget = args.shift();
	if( !elmTarget || !(elmTarget instanceof HTMLElement) ) {
		elmTarget = document.getElementsByTagName("script")[0];
	}
	
	elmTarget.parentNode.insertBefore(elmScript, elmTarget);
	
	return elmScript;
}
