// [for preload images]
import u_ from "@pressmedia/u_";
import csl, { Klass } from "../csl/index";

export default {
	/**
	 * ajax通信のエラーコールバック
	 * @param {Object} xhr
	 * @param {String} textStatus
	 * @param {Object} errorThrown
	 * @returns {String}
	 */
	ajaxErrorCallback: function ajaxErrorCallback(xhr, textStatus, errorThrown) {
		let msg = "<b>Status</b>: " + xhr.status;
		( textStatus && u_.isString(textStatus) ) && (msg += ` (${textStatus})`);
		
		if(xhr.status === 404) {
			msg += "<br>存在しないURLへアクセスしようとしています";
		} else {
			msg += "<br>サーバーとの通信に失敗しました";
		}
		
		if(this instanceof Klass("WebAppBase") && this._environment !== "prod") {
			let responseText = xhr.responseText;
			if( responseText && (/^\{[\S\s]*\}\r?\n?$/).test(responseText) ) {
				responseText = JSON.parse(responseText);
			}
			
			csl.warn("通信失敗", xhr.status, textStatus, responseText);
			
			!!xhr.responseText && (msg += `<br>${xhr.responseText}`);
			!!errorThrown.message && (msg += `<br><br><b>Error message</b>: ${errorThrown.message}`);
		}
		
		return msg;
	},
	
	/**
	 * JSONデータの取得
	 * @param {String} url
	 * @param {Function} [callback]: 完了コールバック
	 * @returns this
	 */
	getJSON: function getJSON(url, callback) {
		if( !url || !u_.isString(url) ) {
			throw new TypeError("arguments error.");
		}
		
		!u_.pathInfo(url).extension && (url += ".json");
		(/^\/(?!\/)/).test(url) && (url = this._relativePath + url);
		
		const xhr = new XMLHttpRequest();
		xhr.onloadend = function() {
			if(this.status === 200) {
				u_.isFunction(callback) && callback(JSON.parse(this.responseText), this);
			}
		};
		xhr.open("GET", url, true);
		xhr.send();
		
		return this;
	},
	
	/**
	 * 第一引数で指定されたHTMLファイルを読み込み、DOMに追加
	 * @param {String} url
	 * @param {Boolean} [async=true]
	 * @param {Boolean} [cache=true]
	 * @param {HTMLElement} [beforeElm={current script}]
	 * @param {Boolean} [leaveBeforeElm=false]
	 * @param {Function} [callback]
	 */
	writeHTML: function writeHTML(url, async, cache, beforeElm, leaveBeforeElm, callback) {
		var
			xhr = new XMLHttpRequest(),
			literal = /\{root\}/g;	// {root}
		
		if(!beforeElm) {
			// get current script
			const s = document.getElementsByTagName("script");
			beforeElm = s[s.length - 1];
		}
		
		if( literal.test(url) ) {
			url = url.replace(literal, this._relativePath);
		} else
		if( (/^\/(?!\/)/).test(url) ) {
			url = this._relativePath + url;
		} else
		if( !(/^\./).test(url) ) {
			url = this._relativePath + "/" + url;
		}
		
		u_.isBoolean(async) || (async = true);
		(cache === false) && ( url += "?_=" + String( ( new Date() ).valueOf() ) );
		
		xhr.onloadend = e => {
			let res = e.target.responseText;
			if(e.target.status === 200) {
				u_.isString(res) && ( res = res.replace(literal, this._relativePath) );
				beforeElm.insertAdjacentHTML("afterend", res);
			}
			!leaveBeforeElm && beforeElm.parentNode.removeChild(beforeElm);
			
			u_.isFunction(callback) && callback(
				(e.target.status >= 200 && e.target.status < 300) ? null : {
					code: e.target.status,
					messages: [res]
				},
				res
			);
		};
		
		xhr.open("GET", url, async);
		xhr.send(null);
		
		return this;
	}
};
