import $ from "jquery";
import { Klass, u_, csl } from "@pressmedia/webappbase";

Klass.create("Vuw", {
	// メンバ変数
	$self: false,
	$template: false,
	
	/**
	* コンストラクタ
	* @param {Object} [opt]: オプション値
	*/
	_initialize(opt) {
		let conf = {	// 設定項目
			/**
			* インスタンス名
			*/
			name: "",
			
			/**
			* selector [string or function]
			* ここで指定したセレクタが、getReady()時に$オブジェクトとして$selfプロパティに格納される
			* （functionを指定する場合は、$オブジェクトを返す様にする）
			* ただし、指定された要素にdata-vuw-template属性が指定されている場合は$templateプロパティ
			* に格納され、body直下のテンプレート配置領域に移動される
			* 元の場所には<var data-vuw="{name}"></var>が配置される
			*/
			selector: "",
			
			/**
			* template生成関数
			* $オブジェクトを返す様にすると、getReady実行後に$templateプロパティに格納される
			*/
			createTemplate() {},
			
			/**
			* DOM操作準備関数のコールバック
			* ※非同期対応可
			*/
			onReady($self) {},
			
			// DOM操作準備関数のコールバック（必ず最初に実行される） ※非同期対応可
			onReadyFirst($self) {},
			// DOM操作準備関数のコールバック（必ず最後に実行される） ※非同期対応可
			onReadyLast($self) {},
			
			/**
			* 状態が変化した際のコールバック
			* ※非同期対応可
			*/
			onChangeState($self) {},
			
			/**
			* DOM更新用関数
			* $instanceかHTMLElementをreturnすると、selectorで指定した要素（$self）が置き換わる
			* 状態が変化した際（上記onChangeState後）に実行される
			* ※非同期対応不可
			* @param {$ instance} $templateClone: $templateから生成したクローン
			*/
			render($templateClone) {},
			
			/**
			* renderの完了コールバック
			* @param {$ instance | HTMLElement} renderedElm: render関数で返されたデータ
			*/
			renderComplete: function(renderedElm) {}
		};
		
		conf = u_.isObject(opt) ? opt : {};
		
		// nameが未指定の場合は現在時間から生成
		!conf.name && ( conf.name = String( ( new Date() ).valueOf() ) );
		
		this.isReady = false;
		this.state = {};		// 状態オブジェクト
		this._onReadyCallbacks = [];		// onReady用
		this._onChangeStateCallbacks = [];		// onChangeState用
		this._stateBooked = {};		// _bookState用
		this._stateBookedCBs = [];		// _bookState用
		
		return this.setProp(conf);
	},
	
	/**
	* 自要素のゲッター
	* @param {string} k: プロパティ名
	*/
	getProp(k) {
		return u_.hasProperty(this, k) ? this[k] : undefined;
	},
	
	/**
	* 自要素のセッター
	* ※第１引数：kがobjectの場合、第２引数をisOverrideとみなします
	* @param {String | Object} k: objectの場合は自身にマージされる
	* @param {mixed} v: kがstringの場合、値として登録される
	* @param {Boolean} [isOverride=false]: trueの場合、既に存在していた場合に上書きを行う
	* @return this
	*/
	setProp(k, v, isOverride) {
		if(k) {
			if( u_.isString(k) ) {
				if( u_.hasProperty(this, k) && !isOverride ) {
					csl.warn((this.name || "Vuw") + ".setProp() ... '" + k + "' is already defined.");
				} else {
					this[k] = v;
				}
				
			} else
			if( u_.isObject(k) ) {
				isOverride = (v === true);
				
				// 第１引数にobjectが指定された場合、onReadyとonChangeStateは上書きではなく追加とする
				if(k.onReady) {
					this.onReady(k.onReady);
					delete k.onReady;
				}
				if(k.onChangeState) {
					this.onChangeState(k.onChangeState);
					delete k.onChangeState;
				}
				
				Object.keys(k).forEach(prop => {
					this.setProp(prop, k[prop], isOverride);
				});
			}
			
		} else {
			csl.warn((this.name || "Vuw") + ".setProp() ... arguments[0] is required.");
		}
		return this;
	},
	
	/**
	* テンプレート退避領域の登録先
	*/
	_templateAreaPropTo: "_tmp",
	
	/**
	* テンプレート退避領域の取得
	* @param {Boolean} [as$]: falseの場合にHTMLElementとして返す
	* @return {$object | HTMLElement}
	*/
	getTemplateArea(as$) {
		if(!Klass("Vuw")._$templateArea) {
			// [create _$templateArea]
			Klass("Vuw")._$templateArea = $("<div/>").addClass("vuw-template-area").hide()
			.appendTo(document.body);
		}
		return (as$ === false) ? Klass("Vuw")._$templateArea.get(0) : Klass("Vuw")._$templateArea;
	},
	
	/**
	* DOM操作準備完了コールバック
	* （documentの読み込み後に実行すること）
	* @return {Promise}
	*/
	async getReady() {
		if(this.isReady) {
			csl.log("** " + this.name + ".getReady() is already executed.");
			return false;
		}
		
		if(this.isGettingReady) {
			csl.log("** " + this.name + " is getting ready now...");
			return false;
		}
		
		this.isGettingReady = true;
		
		// selectorから$selfないし$templateを生成
		if(this.selector) {
			let $elm;
			if( u_.isString(this.selector) ) {
				$elm = $(this.selector);
			} else
			if( u_.isFunction(this.selector) ) {
				$elm = this.selector.call(this);
			}
			
			if($elm && $elm instanceof $ && $elm.length) {
				if( $elm.attr("data-vuw-template") !== undefined ) {
					// data-vuw-template属性がある場合はテンプレートとみなし、$templateプロパティに格納
					this.$template = $elm;
					// 代替要素を配置し、$selfプロパティに格納
					$elm = $("<var/>").attr("data-vuw", this.name).insertBefore(this.$template);
					this.$self = $elm;
				} else {
					// $selfプロパティに格納
					this.$self = $elm;
					// イベントのコールバック等で使用できる様に$.dataに自身を登録
					$.data(this.$self.get(0), "vuw", this);
				}
			}
		}
		
		// createTemplateが指定されてされている場合、実行して$templateを生成
		if( u_.isFunction(this.createTemplate) ) {
			let $elm;
			$elm = this.createTemplate.call(this);
			if($elm && $elm instanceof $ && $elm.length) {
				this.$template = $elm;
			}
		}
		
		// $templateが生成されている場合、body直下の専用領域に退避する
		if(this.$template) {
			!!this.$template.attr("id") && this.$template.attr({
				"data-id": this.$template.attr("id"),
				"id": null
			});
			this.$template.appendTo( this.getTemplateArea() );
		}
		
		// isReady -> ON
		this.isReady = true;
		
		const rtn = [];
		
		if( u_.isFunction(this.onReadyFirst) ) {
			// execute onReadyFirst
			const res = await this.onReadyFirst(this.$self, this.$template);
			rtn.push(res);
		}
		
		// コールバックの実行
		const methods = this._onReadyCallbacks.map(fn => {
			return fn.call(this, this.$self, this.$template);
		});
		
		if(methods.length) {
			const res = await Promise.all(methods);
			rtn.push(...res);
		}
		
		if( u_.isFunction(this.onReadyLast) ) {
			// execute onReadyLast
			const res = await this.onReadyLast(this.$self, this.$template);
			rtn.push(res);
		}
		
		delete this.isGettingReady;
		
		return rtn;
	},
	
	/**
	* DOM操作準備関数のコールバック登録関数
	* @return this
	*/
	onReady(fn) {
		if( u_.isFunction(fn) ) {
			if(this.isReady) {
				fn.call(this, this.$self, this.$template);
			} else {
				this._onReadyCallbacks.push(fn);
			}
		}
		return this;
	},
	
	/**
	* 状態オブジェクトのゲッター
	* @param {String} [k]: キー
	* @return {mixed} キーに紐付いた値
	*/
	getState(k) {
		if(!this.isReady) {
			csl.warn(this.name + ".getState() ... getReady() didn't execute yet.");
			return false;
		} else
		if( k && u_.isString(k) ) {
			return this.state[k];
		} else {
			return this.state;
		}
	},
	
	/**
	* 状態オブジェクトのセッター
	* （changeStateが実行された後、$selfにchangeイベントがトリガーされます）
	* @param {String | Object} k: objectの場合は元のデータに置換される
	* @param {mixed} v: kがstringの場合、値として登録される
	* @return {Promise}
	*/
	async setState(k, v) {
		if(!this.isReady) {
			const err = `${this.name}.setState() ... getReady() didn't execute yet.`;
			csl.warn(err);
			throw new Error(err);
		}
		
		if(!k) {
			const err = `${this.name}.setState() ... arguments[0] is required.`;
			csl.warn(err);
			throw new Error(err);
		}
		
		let state = { ...this.state };
		
		if( u_.isString(k) ) {
			if(this.isChangingState) {
				// [book]
				const nextState = {};
				nextState[k] = v;
				return new Promise(resolve => this._bookState(nextState, resolve));
				
			} else {
				// [state change]
				state[k] = v;
			}
		} else
		if( u_.isObject(k) ) {
			if(this.isChangingState) {
				// [book]
				return new Promise(resolve => this._bookState(k, resolve));
				
			} else {
				// [state change]
				state = k;
			}
		} else {
			const err = `${this.name}.setState() ... arguments error.`;
			csl.warn(err);
			throw new Error(err);
		}
		
		// call changeState
		const res = await this.changeState(state);
		
		return new Promise(resolve => {
			resolve(res);
			!!this.$self && this.$self.trigger("changeState");
			
			// apply booked (if it has.)
			this._setStateBooked();
		});
	},
	
	/**
	* 状態が変化の予約関数（setState()でisChangingState: trueの場合に実行される）
	* @return this
	*/
	_bookState(state, cb) {
		Object.assign(this._stateBooked, state);
		this._stateBookedCBs.push(cb);
		return this;
	},
	
	/**
	* 状態変化の予約用コールバックの実行関数
	* @return {Promise}
	*/
	async _setStateBooked() {
		if( !Object.keys(this._stateBooked).length ) {
			return false;
		}
		
		const stateBooked = { ...this._stateBooked };
		this._stateBooked = {};
		await this.setState(stateBooked);
		
		csl.log.gray(`${this.name} ... set booked state`, {
			CB_count: this._stateBookedCBs.length,
			...stateBooked
		});
		
		const len = this._stateBookedCBs.length;
		if(len) {
			for(let i = 0; i < len; i++) {
				this._stateBookedCBs[i]();
			}
			this._stateBookedCBs = [];
		}
		
		return true;
	},
	
	/**
	* 状態が変化した際に呼び出される関数
	* @return {Promise}
	*/
	async changeState(state) {
		if( !u_.isObject(state) ) {
			throw new TypeError("state must be object.");
		}
		
		this.isChangingState = true;
		
		// コールバックの実行
		const methods = this._onChangeStateCallbacks.map(fn => {
			return fn.call(this, state);
		});
		
		try {
			const res = await Promise.all(methods);
			
			this.state = state;
			const renderedElm = this._execRender(...res);
			
			this.isChangingState = false;
			return renderedElm;
			
		} catch(e) {
			this.isChangingState = false;
			throw new Error(e);
		}
	},
	
	/**
	* 状態が変化した際のコールバック登録関数
	* @return this
	*/
	onChangeState(fn) {
		u_.isFunction(fn) && this._onChangeStateCallbacks.push(fn);
		return this;
	},
	
	/**
	* $templateからcloneを生成して返す
	* @return {$object}
	*/
	getCloneFromTemplate() {
		let $clone = false;
		if(this.$template) {
			$clone = this.$template.clone().attr({
				"id": this.$template.attr("data-id") || null,
				"data-id": null,
				"data-vuw-template": null
			}).removeClass("template");
		}
		return $clone;
	},
	
	/**
	* renderの実行関数
	* @return {Promise}
	*/
	_execRender(...args) {
		args.unshift( this.state );
		args.unshift( this.getCloneFromTemplate() );
		
		let renderedElm = this.render.apply(this, args);
		
		if(this.$self && renderedElm) {
			// render()の戻り値でDOM要素を更新
			if( !(renderedElm instanceof $) && (
				u_.isElement(renderedElm) || u_.isString(renderedElm)
			) ) {
				renderedElm = $(renderedElm);
			}
			
			if(renderedElm instanceof $ && renderedElm.length) {
				this.$self.replaceWith(renderedElm);
				this.$self = renderedElm;
				$.data(this.$self.get(0), "vuw", this);
				
				// 完了コールバックの実行
				u_.isFunction(this.renderComplete) && this.renderComplete(renderedElm);
			}
		}
		
		return renderedElm;
	},
	
	/**
	* DOM更新用関数
	* 状態が変化した際（上記setState後）に実行される
	* returnで$elementを返すと$selfと置き換わる
	* @param {$object} $templateClone: $templateから生成したクローン
	*/
	render($templateClone, state) {},
	
	/**
	* renderの完了コールバック
	* @param {$object | HTMLElement} renderedElm: render関数で返されたデータ
	*/
	renderComplete(renderedElm) {}
});
