import { Klass } from "../csl/index";
import core from "./core";
import historyAPI from "./historyAPI";
import webStorageAPI from "./webStorageAPI";
import scrollPositon from "./scrollPosition";
import imgPreload from "./imgPreload";
import xhr from "./xhr";
import require from "./require";
import dnd from "./dnd";

// [for version info]
const version = {
	_ver: "5.3.0",
	
	/**
	 * バージョン情報を取得
	 * @returns {String}
	 */
	getVersion: function() {
		return this._ver;
	},
};

const prop = Object.assign(
	core,
	historyAPI,
	webStorageAPI,
	scrollPositon,
	imgPreload,
	xhr,
	require,
	dnd,
	version
);

export default Klass.create("WebAppBase", prop);
export { Klass };
