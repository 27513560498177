// [for require]
import u_ from "@pressmedia/u_";

export default {
	_imports: {},
	
	/**
	 * 第一引数で指定されたパス(script || stylesheet)を読み込む関数
	 * @param {String | Array | Object} path
	 * @param {Object} [opt={success:undefined}]
	 * @param {HTMLElement} [elmTarget]
	 * @param {Number} [idx]
	 * @returns this
	 */
	require(path, opt, elmTarget, idx) {
		if(!path) {
			throw new TypeError("arguments error.");
		}
		
		// ** opt {object} **
		// - success {function}
		// - error {function}
		// - doc {DOM Document} default: global.document
		// - type {string} "script" || "stylesheet", default: "script"
		u_.isObject(opt) || (opt = {
			success: opt
		});
		u_.isFunction(opt.success) || (opt.success = null);
		
		if( !opt.doc || !u_.isObject(opt.doc) || !u_.isFunction(opt.doc.createElement) ) {
			opt.doc = document;
		}
		
		( opt.type && u_.isString(opt.type) ) || (opt.type = "script");
		
		if(!elmTarget) {
			// get current script
			const tag = (opt.type === "stylesheet") ? "link" : opt.type;
			const elm = opt.doc.getElementsByTagName(tag);
			elmTarget = !!elm.length ? elm[elm.length - 1] : false;
		}
		
		if( u_.isString(path) ) {
			// [string]
			opt.docKey = (opt.doc === document) ? "global" : opt.doc.title;
			if(!opt.docKey) {
				opt.docKey = `frame${( new Date() ).valueOf()}`;
				opt.doc.title = opt.docKey;
			}
			
			u_.isObject(this._imports[opt.docKey]) || (this._imports[opt.docKey] = {});
			
			if( !u_.isObject(this._imports[opt.docKey][opt.type]) ) {
				this._imports[opt.docKey][opt.type] = {};
			}
			
			opt.callback = function(err) {
				// thisはelement
				if(err) {
					u_.isFunction(opt.error) && opt.error.call(this);
				} else {
					u_.isFunction(opt.success) && opt.success.call(this);
				}
			};
			
			// 拡張子がない場合は付与
			const extensions = {
				"script": "js",
				"stylesheet": "css"
			};
			Object.keys(extensions).forEach(k => {
				const ext = `.${extensions[k]}`;
				if( k === opt.type && !( new RegExp( RegExp.escape(ext) + "$") ).test(path) ) {
					path += ext;
				}
			});
			
			if( u_.hasProperty(this._imports[opt.docKey][opt.type], path) ) {
				if( u_.isFunction(this._imports[opt.docKey][opt.type][path]) ) {
					// 実行中
					this._imports[opt.docKey][opt.type][path](opt.callback);
					
				} else {
					// 実行完了済
					const imported = this._imports[opt.docKey][opt.type][path];
					opt.callback.call(imported.element, imported.error);
				}
				
			} else {
				// 未実行
				// create element
				opt.path = path;
				(/^\/(?!\/)/).test(opt.path) && (opt.path = this._relativePath + opt.path);
				
				let elm;
				switch(opt.type) {
					case "script": {
						(/\.js$/).test(opt.path) || (opt.path += ".js");
						elm = opt.doc.createElement("script");
						elm.src = opt.path;
						elm.async = 1;
					} break;
						
					case "stylesheet": {
						elm = opt.doc.createElement("link");
						elm.rel = "stylesheet";
						elm.href = opt.path;
					} break;
				}
				
				u_.isObject(opt.attr) && Object.keys(opt.attr).forEach(k => {
					u_.isString(opt.attr[k]) && elm.setAttribute(k, opt.attr[k]);
				});
				
				// append to document
				this._imports[opt.docKey][opt.type][path] = (function(fn) {
					u_.isFunction(fn) && this._imports[opt.docKey][opt.type][path]._callbacks.push(fn);
				}).bind(this);
				this._imports[opt.docKey][opt.type][path]._callbacks = [];
				this._imports[opt.docKey][opt.type][path](opt.callback);
				
				elm.callback = function(err, k, _importsByType) {
					if( u_.isFunction(_importsByType[k]) ) {
						_importsByType[k]._callbacks.forEach(function(fn) {
							fn.call(this, err);
						}, this);
					}
					_importsByType[k] = {
						error: err,
						element: this
					};
				};
				
				elm.onload = elm.callback.bind(elm, null, path, this._imports[opt.docKey][opt.type]);
				elm.onerror = elm.callback.bind(elm, true, path, this._imports[opt.docKey][opt.type]);
				
				if(elmTarget) {
					elmTarget.parentNode.insertBefore(elm, elmTarget.nextSibling);
				} else {
					opt.doc.head.appendChild(elm);
				}
			}
			
		} else
		if( Array.isArray(path) ) {
			if(u_.isNumber(idx) && path[idx]) {
				this.require(path[idx], opt, elmTarget);
				
			} else {
				// [array (parallel)]
				const arg1 = {
					count: 0,
					len: path.length
				};
				
				arg1.cb = {};
				u_.isFunction(opt.success) && (arg1.cb.success = function() {
					arg1.count++;
					// 全て読み込み終わったらコールバックを実行
					(arg1.count === arg1.len) && opt.success.call(elmTarget || this);
				});
				u_.isFunction(opt.error) && (arg1.cb.error = opt.error);
				
				path.forEach(item => {
					this.require(item, arg1.cb, elmTarget);
					elmTarget = elmTarget.nextSibling;
				});
			}
		} else
		if( u_.isObject(path) ) {
			// [sync]
			if( Array.isArray(path.sync) ) {
				(!u_.isNumber(idx) || idx < 0) && (idx = 0);
				const arg1 = {
					_self: this,
					success: function() {
						idx++;
						if(path.sync[idx]) {
							arg1._self.require(path, opt, this, idx);
						} else {
							u_.isFunction(opt.success) && opt.success.call(this);
						}
					}
				};
				
				u_.isFunction(opt.error) && (arg1.error = opt.error);
				this.require(path.sync[idx], arg1, elmTarget);
			} else
			
			// [match]
			if(path.match) {
				u_.isString(path.match) && (path.match = [path.match]);
				let flg = false;
				
				if( Array.isArray(path.match) ) {
					const se = /^\^|\$$/g;
					let matches = 0;
					
					path.match.forEach(match => {
						const escaped = match.match(se);
						if(escaped) {
							(escaped.length === 1 && escaped[0] === "$") && escaped.unshift("");
							match = match.replace(se, "");
						}
						
						match = RegExp.escape(match);
						Array.isArray(escaped) && ( match = escaped[0] + match + (escaped[1] || "") );
						
						const literal = new RegExp(match);
						literal.test(this.currentPath) && (matches++);
					});
					
					flg = !path.and ? !!matches : (matches === path.match.length);
				}
				
				if(flg) {
					if(path.then) {
						this.require(path.then, opt, elmTarget);
					} else {
						u_.isFunction(opt.success) && opt.success.call(elmTarget);
					}
				} else {
					if(path["else"]) {
						this.require(path["else"], opt, elmTarget);
					} else {
						u_.isFunction(opt.success) && opt.success.call(elmTarget);
					}
				}
			} else
			
			// [path, fallback]
			if( u_.isString(path.path) ) {
				const arg1 = {
					_self: this,
				};
				!!opt.type && (arg1.type = opt.type);
				!!opt.doc && (arg1.doc = opt.doc);
				u_.isFunction(opt.success) && (arg1.success = opt.success);
				
				if( path.fallback && u_.isString(path.fallback) ) {
					arg1.error = function() {
						arg1._self.require(path.fallback, opt, this);
					};
				} else
				if( u_.isFunction(opt.error) ) {
					arg1.error = opt.error;
				}
				
				u_.isObject(path.attr) && (arg1.attr = path.attr);
				
				this.require(path.path, arg1, elmTarget);
			}
		}
		
		return this;
	}
};
