import $ from "jquery";
import { Klass, u_, csl } from "@pressmedia/webappbase";
import "./klass.Vuw";

const vuwer = Klass.new_("Vuw", {
	name: "vuwer",
	selector() {
		return $(window);
	},
	
	_vuwMap: {},
	childKlass: "VuwerComponent",
	
	/**
	 * add関数で追加されたVuwのgetReady関数を実行する
	 * @returns {Promise}
	 */
	async _getComponentsReady() {
		const res = await Promise.all( Object.keys(this._vuwMap).map(name => {
			const vuw = this._vuwMap[name];
			if(vuw instanceof Klass(this.childKlass) && !vuw.isReady) {
				return vuw.getReady();
			}
		}) );
		
		const names = Object.keys(this._vuwMap);
		const rtn = {};
		
		// 各getReady()内でresolveによって渡された値を戻り値に格納
		res.forEach((res, i) => {
			if( Array.isArray(res) ) {
				res = res.filter(arg => {
					return !!arg &&
						(!u_.isObject(arg) || Object.keys(arg).length) &&
						(!Array.isArray(arg) || arg.length);
				});
				!res.length && (res = undefined);
			}
			
			(res === undefined) || (rtn[ names[i] ] = res);
		});
		
		return rtn;
	},
	
	/**
	 * DOM操作の準備完了コールバック
	 * @param {$object} $self
	 * @returns {Promise}
	 */
	onReady($self) {
		this.$window = $self;
		return this._getComponentsReady();
	},
	
	/**
	 * 子Vuwの追加関数
	 * @param {String} name: Vuwの名称 （optがVuwインスタンスの場合はvuwerへの登録名)
	 * @param {Object | Vuw instance} [opt]: Vuwコンストラクタのオプション | childKlassで指定したKlassのインスタンス
	 * @param {Function} [callback]: 追加したVuwに対する処理（this = 追加したVuw）※非同期非対応
	 * @param {Boolean} [isOverride=false]: true->既に存在していた場合に上書きを行う
	 * @return this
	 */
	add(name, opt, callback, isOverride) {
		// 第3引数がbooleanの場合の処理
		(typeof callback === "boolean") && (isOverride = callback);
		
		if( !u_.isString(name) ) {
			csl.warn.red(`${this.name}.add() ... arguments[0] is must be string.`, typeof name);
			return this;
		}
		if(name in this._vuwMap && !isOverride) {
			csl.warn(`${this.name}.add() ... '${name}' is already defined.`);
			return this;
		}
		
		let vuw;
		
		if( u_.isObject(opt) ) {
			if( opt instanceof Klass(this.childKlass) ) {
				// 第2引数がVuwインスタンスの場合
				vuw = opt;
				if( vuw.container instanceof Klass("Vuw") ) {
					// コンテナに紐付いている場合は外す
					delete vuw.container._vuwMap[vuw.name];
				}
			}
		} else {
			opt = {};
		}
		
		Object.assign(opt, {
			name: name,
			container: this
		});
		
		if(!vuw) {
			let klass;
			if( opt.vuwType && u_.isString(opt.vuwType) ) {
				klass = `Vuw${opt.vuwType.slice(0, 1).toUpperCase()}${opt.vuwType.slice(1)}`;
				if( !u_.isFunction( Klass(klass) ) ) {
					klass = this.childKlass;
					opt.vuwType = "component";
				}
			} else {
				klass = this.childKlass;
				opt.vuwType = "component";
			}
			
			vuw = Klass.new_(klass, opt);
		}
		
		this._vuwMap[name] = vuw;
		
		u_.isFunction(callback) && callback.call(vuw);
		
		return this;
	},
	
	/**
	 * 子Vuwの取得関数
	 * @param {String} name: Vuwの名称 | アドレス
	 * @return Vuw instance | false
	 */
	get(name) {
		let rtn = false;
		if( !u_.isString(name) ) {
			csl.warn(`${this.name}.get() ... arguments[0] is must be string.`, typeof name);
			return rtn;
		}
		
		rtn = this._vuwMap[name] || false;
		
		if( !rtn && (/\./).test(name) ) {
			// 引数にアドレスを指定した場合
			const arr = name.split(".");
			rtn = this._vuwMap[ arr.pop() ] || false;
			for(let i = arr.length - 1; i >= 0; i--) {
				if(rtn) {
					if(rtn._vuwMap) {
						rtn = rtn._vuwMap[ arr[i] ] || false;
					} else {
						rtn = false;
						break;
					}
				} else {
					break;
				}
			}
		}
		
		return rtn;
	},
	
	/**
	 * 子Vuwリストの取得関数
	 * @return {Array}
	 */
	getChildren() {
		const list = Object.keys(this._vuwMap).map(k => {
			return this._vuwMap[k];
		});
		list.get = function(idx) {
			if( u_.isNumber(idx) ) {
				(idx < 0) && (idx = this.length + idx);
				return this[idx] || false;
			} else {
				return this;
			}
		};
		return list;
	},
	
	/**
	 * 末端階層まで対象にしたVuwリストの取得関数
	 * @param {String} name:Vuwインスタンスのname
	 * @return {Array}
	 */
	find(name) {
		let list = [];
		this.getChildren().forEach(vuw => {
			(vuw.name === name) && list.push(vuw);
			list = list.concat( vuwer.find.call(vuw, name) );
		});
		list.get = function(idx) {
			if( u_.isNumber(idx) ) {
				(idx < 0) && (idx = this.length + idx);
				return this[idx] || false;
			} else {
				return this;
			}
		};
		return list;
	},
	
	/**
	 * 子Vuwの削除関数
	 * @param {String} name: Vuwインスタンスのname
	 * @return this
	 */
	remove(name) {
		if( !u_.isString(name) ) {
			csl.warn(`${this.name}.remove() ... arguments[0] is must be string.`, typeof name);
			return this;
		}
		
		const vuw = this.get(name);
		if(!vuw) {
			return this;
		}
		
		(vuw.$self && vuw.$self.length) && vuw.$self.remove();
		(vuw.$template && vuw.$template.length) && vuw.$template.remove();
		vuw.isRemoved = true;
		delete this._vuwMap[name];
		return this;
	},
	
	/**
	 * 全ての子Vuwの削除関数
	 * @param {String} name: Vuwインスタンスのname
	 * @return this
	 */
	removeChildren() {
		this.getChildren().forEach(vuw => this.remove(vuw.name));
		return this;
	},
	
	/**
	 * Class 継承用関数
	 * @param {String} name: 継承先の登録名称
	 * @param {Object} [prop]: 継承先のプロパティ
	 * @param {String | function} [parent]: 継承元の名称 | 継承元Class
	 * @return this
	 */
	appendKlass(name, prop, parent) {
		if( !u_.isString(name) ) {
			csl.warn.red(`${this.name}.appendKlass() ... arguments[0] is must be string.`, typeof name);
			return this;
		} else
		if( Klass(name) ) {
			csl.warn.red(`${this.name}.appendKlass() ... '${name}' is already defined.`);
			return this;
		}
		
		if( u_.isString(parent) ) {
			if( !u_.isFunction( Klass(parent) ) ) {
				csl.warn(`${this.name}.appendKlass() ... '${parent}' is undefined.`);
				return this;
			}
		} else {
			// default
			if( !u_.isFunction( Klass(this.childKlass) ) ) {
				csl.warn(`${this.name}.appendKlass() ... '${this.childKlass}' is undefined.`);
				return this;
			}
			parent = this.childKlass;
		}
		
		Klass.create(name).extends_(parent, prop);
		return this;
	}
});

export default vuwer;
