import $ from "jquery";
import { Klass, u_, csl } from "@pressmedia/webappbase";
import vuwer from "./vuwer";

vuwer
/*************************************
* define VuwerComponent (extends Vuw) *
*************************************/
// prototype継承して登録
.appendKlass("VuwerComponent", {
	childKlass: "VuwerComponent",
	
	/**
	 * コンストラクタ
	 * @param {Object} [opt]: オプション値
	 * @returns this | false
	 */
	_initialize(opt) {
		if( Klass("VuwerComponent").parent._initialize.call(this, opt) ) {
			if(!this.childKlass) {
				csl.warn("VuwerComponent._initialize() ... 'childKlass' is required prop.");
				return false;
			}
			
			if( !u_.isString(this.childKlass) || !Klass(this.childKlass) ) {
				csl.warn("VuwerComponent._initialize() ... 'childKlass' is must be Klass.");
				return false;
			}
			
			this._vuwMap = {};
			
			// [push onReady callback]
			this.onReady( vuwer._getComponentsReady.bind(this) );
			return this;
			
		} else {
			return false;
		}
	},
	
	/**
	 * 子Vuwの追加関数
	 * @param {String} name: Vuwの名称 （optがVuwインスタンスの場合はvuwerへの登録名)
	 * @param {Object | Vuw instance} [opt]: Vuwコンストラクタのオプション | childKlassで指定したKlassのインスタンス
	 * @param {Function} [callback]: 追加したVuwに対する処理（this = 追加したVuw）※非同期非対応
	 * @param {Boolean} [isOverride=false]: true->既に存在していた場合に上書きを行う
	 * @return this
	 */
	add(name) {
		vuwer.add.apply( this, u_.getAsArray(arguments) );
		return this;
	},
	
	/**
	 * 子Vuwの取得関数
	 * @param {String} name: Vuwの名称 | アドレス
	 * @return Vuw instance | false
	 */
	get(name) {
		return vuwer.get.call(this, name);
	},
	
	/**
	 * 子Vuwリストの取得関数
	 * @return {Array}
	 */
	getChildren() {
		return vuwer.getChildren.call(this);
	},
	
	/**
	 * 末端階層まで対象にしたVuwリストの取得関数
	 * @param {String} name:Vuwインスタンスのname
	 * @return {Array}
	 */
	find(name) {
		return vuwer.find.call(this, name);
	},
	
	/**
	 * 子Vuwの削除関数
	 * @param {String} name:Vuwインスタンスのname
	 * @return this
	 */
	remove(name) {
		const targetVuw = this.get(name);
		if(targetVuw) {
			targetVuw.getChildren().forEach(vuw => targetVuw.remove(vuw.name));
			vuwer.remove.call(this, name);
		}
		return this;
	},
	
	/**
	 * 全ての子Vuwの削除関数
	 * @param {String} name: Vuwインスタンスのname
	 * @return this
	 */
	removeChildren() {
		return vuwer.removeChildren.call(this);
	},
	
	/**
	 * vuwerに登録されている他のvuwを取得
	 * @param {String} name: Vuwの名称 | アドレス
	 * @return Vuw instance
	 */
	getOther(name) {
		return this.container.get(name);
	},
	
	/**
	* vuwerからのアドレスを取得
	* @return string
	*/
	getAddress() {
		const addr = !arguments.length ? [] : arguments[0];
		addr.push(this.name);
		if(this.container && this.container.getAddress) {
			return this.container.getAddress(addr);
		} else {
			// getAddressを持たないcontainer === vuwer
			return addr.join(".");
		}
	},
	
	/**
	* $templateからcloneを生成して返す
	* （$templat内部に[data-vuw]プロパティを持つ要素が存在した場合に
	* 対象のVuwのstateの書き換えを行う）
	* @return $clone
	*/
	getCloneFromTemplate() {
		const $clone = Klass("Vuw").prototype.getCloneFromTemplate.call(this);
		
		if($clone) {
			$clone.find("[data-vuw]").each((i, elm) => {
				const $elm = $(elm);
				const vuwName = $elm.data("vuw");
				const vuw = this.get(vuwName);
				
				if(vuw) {
					vuw.setProp("$self", $elm, true);
					const state = this.getState(vuwName);
					u_.isObject(state) && vuw.setState(state);
				}
			});
		}
		return $clone;
	}
}, "Vuw");
