import { Klass } from "@pressmedia/webappbase";

import init from "./init";
import display from "./display";
import contents from "./contents";
import displayContents from "./displayContents";

const WAB = Klass("WebAppBase").prototype;

WAB.popup = {
	_version: "2.2.1",
	getVersion() {
		return this._version;
	},
	
	...init, ...display, ...contents, ...displayContents,
};
