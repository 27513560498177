import u_ from "@pressmedia/u_";
import Klass from "../klass";

const Console = Klass.create("Console", {
	// [constructor]
	_initialize: function() {
		// default methods [log, warn]
		return this.off("log").off("warn");
	},
	
	// color settings.
	colors: {
		blue: "#0862b1",
		green: "#0c861a",
		orange: "#de6d0b",
		red: "#e2291e",
		purple: "#920ea5",
		gray: "#aaa",
		grey: "#aaa"
	},
	
	_attachColors: function(method, isOn) {
		const isIE = (Klass("Console").browser[0] === "ie");
		
		Object.keys(this.colors).forEach(k => {
			if( isOn && u_.isFunction(this[method]) ) {
				if(isIE) {
					this[method][k] = this[method];
				} else {
					this[method][k] = this[method].bind(console, "%c%s", "color:" + this.colors[k] + ";");
				}
			} else {
				this[method][k] = function() {};
			}
		});
		
		return this;
	},
	
	on: function(method) {
		if( !u_.isString(method) ) {
			throw new TypeError("arguments[0] must be string.");
		}
		
		if( u_.isFunction(console[method]) ) {
			this[method] = console[method].bind(console);
			(["log", "warn"].indexOf(method) >= 0) && this._attachColors(method, true);
		} else {
			this.off(method);
		}
		
		return this;
	},
	
	off: function(method) {
		if( !u_.isString(method) ) {
			throw new TypeError("arguments[0] must be string.");
		}
		
		this[method] = function() {};
		(["log", "warn"].indexOf(method) >= 0) && this._attachColors(method, false);
		
		return this;
	}
});

import browserInfo from "./browserInfo";
import deviceInfo from "./deviceInfo";

Console.browser = browserInfo;
Console.device = deviceInfo;

export default Console;
export { Klass };
