import { u_ } from "@pressmedia/webappbase";
import $ from "jquery";

export default {
	/**
	* ポップアップコンテンツの追加関数
	* @param {String} keyName: 任意のコンテンツ名
	* @param {$object | HTMLElement} elm: コンテンツの中身
	* @return this
	*/
	addContent(keyName, elm) {
		const $elm = (elm instanceof $) ? elm : $(elm);
		if(!$elm.length) {
			return false;
		}
		
		$elm.addClass("ppup-content " + keyName).appendTo(this._$wrap);
		this.contents[keyName] = $elm;
		return this;
	},
	
	/**
	* ポップアップコンテンツの削除関数
	* @param {String} keyName: 対象のコンテンツ名
	* @return this
	*/
	removeContent(keyName) {
		console.log("removeContent", this.contents);
		if( u_.hasProperty(this.contents, keyName) ) {
			this.contents[keyName].remove();
			delete this.contents[keyName];
		}
		return this;
	},
	
	/**
	 * 内部関数（getContents用）
	 * @param {Array} arr: コンテンツ名のリスト
	 * @returns $object
	 */
	_getContents(arr) {
		if( !Array.isArray(arr) ) {
			throw new TypeError("arguments[0] must be Array.")
		}
		
		let $arr;
		
		arr.forEach(k => {
			if( u_.hasProperty(this.contents, k) ) {
				if($arr) {
					$arr.add( this.contents[k] );
				} else {
					$arr = this.contents[k];
				}
			}
		});
		
		return $arr;
	},
	
	/**
	* ポップアップコンテンツの取得関数
	* @param {String | Array} keyName: 対象のコンテンツ名
	* @return $object
	*/
	getContents(keyName) {
		let arr;
		if( Array.isArray(keyName) ) {
			arr = keyName;
		} else
		if( u_.isString(keyName) ) {
			arr = keyName.split(",").map(t => {
				return t.trim();
			});
		} else {
			return false;
		}
		
		return this._getContents(arr);
	}
};
