import { Klass, csl } from "@pressmedia/webappbase";
import vuwer from "./vuwer";
import "./klass.VuwerComponent";
import "./klass.VuwFade";

const version = {
	_ver: "2.2.0",
	
	/**
	 * バージョン情報を取得
	 * @returns {String}
	 */
	getVersion() {
		return this._ver;
	},
};

Object.assign(Klass("Vuw"), version);

csl.log.grey(`** vuwer (Vuw version:${Klass("Vuw").getVersion()}) **`);

export default vuwer;
