import { Klass, csl } from "@pressmedia/webappbase";
import vuwer from "./vuwer";
import "./klass.VuwerComponent";

vuwer
/******************************************
* define VuwFade (extends VuwerComponent) *
******************************************/
// prototype継承して登録
.appendKlass("VuwFade", {
	delay: 0,
	
	/**
	* コンストラクタ
	* @param {Object} [opt]: オプション値
	*/
	_initialize(opt) {
		if( Klass("VuwFade").parent._initialize.call(this, opt) ) {
			// [unshift onReady callback]
			this._onReadyCallbacks.unshift(this._onReadyDefault);
			
			// [unshift onChangeState callback]
			this._onChangeStateCallbacks.unshift(this._onChangeVisible);
			return this;
		} else {
			return false;
		}
	},
	
	/**
	 * DOM操作の準備完了コールバック
	 * @param {$object} $self
	 * @returns this
	 */
	_onReadyDefault($self) {
		if($self) {
			if( ("absolute_fixed").indexOf( $self.css("position") ) >= 0 ) {
				this.orgHeight = $self.height();
			}
			
			$self.addClass("vuw-fade").attr("data-visible", $self.attr("data-visible") || "0");
			const delay = parseFloat( $self.css("transition-duration") );
			if( isNaN(delay) ) {
				$self.css("transition-duration", `${String(this.delay / 1000)}s`);
			} else {
				this.delay += delay * 1000;
			}
			
			// VuwFadeが連続する場合は親ブロックにvuw-fade-containerクラスを付与
			!!$self.prev(".vuw-fade").length && $self.parent().addClass("vuw-fade-container");
		}
		return this;
	},
	
	/**
	 * 表示ON/OFFの切り替え
	 * @param {Object} state
	 * @returns {Promise}
	 */
	_onChangeVisible(state) {
		const
			cuVisible = !!this.state.visible,
			toVisible = !!state.visible;
		
		if(toVisible !== cuVisible) {
			// visibleが変更になった場合のみ処理を行う
			
			if(!this.$self) {
				csl.log.red("  " + this.name + ".onChangeState", "$self is false.");
				return false;
			}
			
			return new Promise(resolve => {
				// CSSアニメーション様に処理をずらす
				!!this.orgHeight && this.$self.css("height", this.orgHeight);
				// [show or clear]
				this.$self.attr("data-visible", toVisible ? "1" : "");
				setTimeout(() => {
					// [hide]
					toVisible || this.$self.attr("data-visible", "0");
					!!this.orgHeight && this.$self.css("height", "");
					resolve();
				}, this.delay);
			});
		}
	}
}, "VuwerComponent");
