import { u_ } from "@pressmedia/webappbase";

export default {
	// ポップアップ表示中にスクロールをロックするかどうか
	scrollLock: true,
	
	/**
	* ポップアップ表示関数
	* @param {Function} [callback]: 完了コールバック
	* @return Promise
	*/
	show(callback) {
		const fadeTime = (parseFloat( this._$wrap.css("transition-duration") ) || 0.4) * 1000;
		const status = this._$wrap.attr("data-shown");
		
		return new Promise(resolve => {
			if(status === "0") {
				this.scrollLock && this.$parent.css("overflow", "hidden");
				this._$wrap.attr("data-shown", "");
				
				setTimeout(() => {
					this._$wrap.attr("data-shown", "1");
					u_.isFunction(callback) && callback.call(this);
					resolve();
				}, fadeTime);
				
			} else {
				u_.isFunction(callback) && callback.call(this);
				resolve();
			}
		});
	},
	
	/**
	* ポップアップ非表示関数
	* @param {Function} [callback]: 完了コールバック
	* @return Promise
	*/
	hide(callback) {
		const fadeTime = (parseFloat( this._$wrap.css("transition-duration") ) || 0.4) * 1000;
		const status = this._$wrap.attr("data-shown");
		
		return new Promise(resolve => {
			if(status === "1") {
				this._$wrap.attr("data-shown", "");
				setTimeout(() => {
					this._$wrap.attr("data-shown", "0");
					this.scrollLock && this.$parent.css("overflow", "");
					u_.isFunction(callback) && callback.call(this);
					resolve();
				}, fadeTime);
				
			} else {
				u_.isFunction(callback) && callback.call(this);
				resolve();
			}
		});
	}
};
