import { Klass } from "@pressmedia/webappbase";
import exec from "./exec";

const WAB = Klass("WebAppBase").prototype;

Object.assign(exec.exec, {
	_version: PACKAGE_VERSION,
	getVersion() {
		return this._version;
	}
});

Object.assign(WAB, exec);
