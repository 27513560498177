import u_ from "@pressmedia/u_";
import { Klass } from "../csl/index";

export default {
	name: "",
	ACTION_URL:  "",
	_environment: "",
	_GET: {},
	browser: Klass("Console").browser,
	device: Klass("Console").device,
	
	// [constructor]
	_initialize: function(str, rootdir) {
		if( u_.isString(str) ) {
			if( str.slice(0, 4) === "http" || str.slice(0, 1) === "." || str.slice(0, 1) === "/") {
				this.ACTION_URL = str;
			} else {
				this.name = str;
			}
		} else
		if( u_.isObject(str) && !rootdir ) {
			rootdir = str;
		}
		
		this.config = {};
		
		// set rootdir, rootpath
		this.setRootDir(rootdir || location.host);
		
		this._GET = u_.parseQueryString(location.search);
	},
	
	/**
	 * URLの設定
	 * @param {String} [url=location.origin + location.pathname]
	 * @returns this
	 */
	setUrl: function setUrl(url) {
		if( !url || !u_.isString(url) ) {
			url = location.origin + location.pathname;
		}
		
		this.URL = url;
		
		if(this.config.rootpath) {
			const pattern = RegExp.escape(this.config.rootpath);
			
			// [set currentPath]
			this.currentPath = this.URL.replace(new RegExp( "^" + pattern.replace(/\/$/, "") ), "");
			
			// [set relativePath]
			const pageDirLv = this.URL.replace(new RegExp("^" + pattern), "").split("/").length;
			if(pageDirLv === 1) {
				this._relativePath = ".";
				
			} else {
				const arr = [];
				const len = pageDirLv - 1;
				for(let i = 0; i < len; i++) {
					arr.push("..");
				}
				this._relativePath = arr.join("/");
			}
		}
		
		return this;
	},
	
	/**
	 * ルートディレクトリの設定
	 * @param {String | Object} rootdir
	 * @returns this
	 */
	setRootDir: function setRootDir(rootdir) {
		const arr = (location.origin + location.pathname).split("/");
		let i;
		
		if( u_.isObject(rootdir) ) {
			rootdir.dev || (rootdir.dev = "dev");
			rootdir.test || (rootdir.test = "test");
			rootdir.prod || (rootdir.prod = location.host);
			
			i = arr.lastIndexOf(rootdir.dev);
			this._environment = "dev";
			if(i < 0) {
				i = arr.lastIndexOf(rootdir.test);
				this._environment = "test";
				if(i < 0) {
					i = arr.lastIndexOf(rootdir.prod);
					this._environment = "prod";
				}
			}
			
		} else
		if( u_.isString(rootdir) ) {
			i = arr.lastIndexOf(rootdir);
			this._environment = "prod";
		} else {
			throw new TypeError("arguments[0] must be object or string.");
		}
		
		this.config.rootpath = arr.slice(0, i + 1).join("/") + "/";
		this.config.rootdir = arr[i];
		
		return this.setUrl();
	}
};
