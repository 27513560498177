// [for Web Storage API]
import u_ from "@pressmedia/u_";

export default {
	storage: {
		_prefix: "",
		
		/**
		 * 接頭辞の設定
		 * @param {String} prefix: 接頭辞
		 * @returns this
		 */
		setPrefix: function setPrefix(prefix) {
			if( !u_.isString(prefix) ) {
				throw new TypeError("arguments[0] must be string.");
			}
			
			(prefix !== this._prefix) && (this._prefix = prefix);
			return this;
		},
		
		/**
		 * 値の登録
		 * @param {String} key
		 * @param {mixed} value
		 * @returns this
		 */
		set: function set(key, value) {
			if( !u_.isString(key) ) {
				throw new TypeError("arguments[0] must be string.");
			}
			
			if(localStorage) {
				(value && typeof value === "object") && ( value = "JSON." + JSON.stringify(value) );
				localStorage.setItem(this._prefix + key, value);
			}
			return this;
		},
		
		/**
		 * 値の取得
		 * @param {String} key
		 * @returns this
		 */
		get: function get(key) {
			if( !u_.isString(key) ) {
				throw new TypeError("arguments[0] must be string.");
			}
			
			let value = null;
			if(localStorage) {
				value = localStorage.getItem(this._prefix + key);
				if( value && (/^JSON\./).test(value) ) {
					value = JSON.parse( value.replace(/^JSON\./, "") );
				}
			}
			return value;
		},
		
		/**
		 * 値の削除
		 * @param {String} key
		 * @returns this
		 */
		del: function del(key) {
			if( !u_.isString(key) ) {
				throw new TypeError("arguments[0] must be string.");
			}
			
			!!localStorage && localStorage.removeItem(this._prefix + key);
			return this;
		},
		
		/**
		 * ストレージのクリア
		 * @returns this
		 */
		clear: function clear() {
			!!localStorage && localStorage.clear();
			return this;
		}
	}
};

