import { Klass, csl, u_ } from "@pressmedia/webappbase";
import $ from "jquery";
import addAlert from "./addAlert";

export default {
	/**
	* イニシャライズ関数（最初にこの関数を実行して利用）
	* @param {WebAppBase}: WebAppBaseインスタンス
	* @param {object} [opt]: オプション
	* @param {$object} [$parent=$(document.body)]: 親となるDOM要素
	* @return this
	*/
	init(wAB, opt, $parent) {
		if( !( wAB instanceof Klass("WebAppBase") ) ) {
			csl.warn("popup.init", "arguments[0] must be WebAppBase instance.");
			return false;
		}
		(!$parent || !$parent.length) && ( $parent = $(document.body) );
		
		this._wAB = wAB;
		
		const def = {
			// [オプション初期値]
			useCssTransform: (wAB.browser.join("") !== "ie8under")
		};
		
		opt = u_.isObject(opt) ? Object.assign(def, opt) : def;
		Object.keys(opt).forEach(k => {
			this[k] = opt[k];
		});
		
		this._$wrap = $("<div/>").addClass("ppup-wrap").attr({
			"data-shown": "0",
			"data-useCssTransform": this.useCssTransform ? "1" : "0"
		});
		this._$bgElm = $("<div/>").addClass("ppup-bg").appendTo( this._$wrap );
		this._$wrap.appendTo( $parent );
		this.$parent = $parent;
		this.contents = {};
		
		// [default content]
		this._appendDefaultContent();
		
		return this;
	},
	
	/**
	* デフォルトのコンテンツ（アラート表示）の登録関数
	* @return this
	*/
	_appendDefaultContent() {
		addAlert.call(this);
		return this;
	}
};
