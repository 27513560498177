import { u_ } from "@pressmedia/webappbase";
import $ from "jquery";

export default {
	/**
	 * アクティブなコンテンツの情報を更新
	 * @returns this
	 */
	updDataActive() {
		const active = [];
		Object.keys(this.contents).forEach(keyName => {
			this.contents[keyName].hasClass("is-active") && active.push(keyName);
		});
		
		!!active.length && active.sort((a, b) => {
			let
				aIdx = parseInt( this.contents[a].css("z-index") ),
				bIdx = parseInt( this.contents[b].css("z-index") );
			
			isNaN(aIdx) && (aIdx = -1);
			isNaN(bIdx) && (bIdx = -1);
			
			if(aIdx > bIdx) {
				return -1;
			}
			if(aIdx < bIdx) {
				return 1;
			}
			return 0;
		});
		
		this._$wrap.attr( "data-active", active.join(",") );
		
		return this;
	},
	
	/**
	* ポップアップコンテンツの表示関数
	* @param {String} keyName: 対象のコンテンツ名
	* @param {Function} [callback]: 完了コールバック
	* @return promise
	*/
	showContents(keyName, callback) {
		const $elm = this.getContents(keyName);
		
		if(!$elm || !$elm.length) {
			return this;
		}
		
		$elm.addClass("is-active").css({
			"z-index": this._$wrap.children(".is-active").length
		});
		
		this.useCssTransform || setTimeout(() => {
			$elm.each(function() {
				const $this = $(this);
				$this.css({
					"margin-left": $this.outerWidth() / -2,
					"margin-top": $this.outerHeight() / -2
				});
			});
		}, 0);
		
		this.updDataActive();
		
		return this.show(callback);
	},
	
	/**
	 * 内部関数（hideContents用）
	 * @param {String} keyName: 対象のコンテンツ名
	 * @returns $object
	 */
	_hideContents(keyName) {
		const $elm = this.getContents(keyName);
		
		if(!$elm || !$elm.length) {
			return $elm;
		}
		
		const removeCss = {
			"z-index": ""
		};
		
		this.useCssTransform || Object.assign(removeCss, {
			"margin-left": "",
			"margin-top": ""
		});
		
		return $elm.removeClass("is-active").css(removeCss);
	},
	
	/**
	* ポップアップコンテンツの非表示関数
	* @param {String} keyName: 対象のコンテンツ名
	* @param {Function} [callback]: 完了コールバック
	* @return proimse
	*/
	hideContents(keyName, callback) {
		return new Promise(resolve => {
			const len = this._$wrap.children(".is-active").length;
			
			if(len > 1) {
				this._hideContents(keyName);
				resolve();
			} else {
				const $elm = this.getContents(keyName);
				if( $elm && $elm.length && $elm.hasClass("is-active") ) {
					this.hide(function() {
						this._hideContents(keyName);
						resolve();
					});
					
				} else {
					resolve();
				}
			}
		}).then(() => {
			this.updDataActive();
			u_.isFunction(callback) && callback.call(this);
		});
	},
	
	/**
	* 全ポップアップコンテンツの非表示関数
	* @param {Function} [callback]: 完了コールバック
	* @return promise
	*/
	hideAllContents(callback) {
		const removeCss = {
			"z-index": ""
		};
		
		this.useCssTransform || Object.assign(removeCss, {
			"margin-left": "",
			"margin-top": ""
		});
		
		return this.hide(function() {
			this._$wrap.children(".is-active").removeClass("is-active").css(removeCss);
			u_.isFunction(callback) && callback.call(this);
		});
	}
};
