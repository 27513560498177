export default function DOMReadyPromise() {
	return new Promise(resolve => {
		if(document.readyState === "loading") {
			document.addEventListener("DOMContentLoaded", () => {
				resolve(document.readyState);
			});
		} else {
			resolve(document.readyState);
		}
	});
}
