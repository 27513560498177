const version = "3.0.0";

import importScript from "./importScript";

function loadGoogleAnalytics(trackingID, autoSendPageview, callback) {
	if(!trackingID || typeof trackingID !== "string") {
		return false;
	}
	
	// タグの読み込みが完了するまで使用されるものを定義
	Array.isArray(window.dataLayer) || (window.dataLayer = []);
	
	function gtag() {
		window.dataLayer.push(arguments);
	}
	
	gtag( "js", new Date() );
	
	// ** extend gtag **
	gtag.sendPageview = function sendPageview(pagePath) {
		return this("config", trackingID, (pagePath && typeof pagePath === "string") && {
			page_path: pagePath
		});
	};
	(autoSendPageview !== false) && gtag.sendPageview();
	
	window.gtag =	gtag;
	
	// 読み込み用スクリプトの挿入
	const canCreateComment = (typeof document.createComment === "function");
	
	// create script & insert DOM tree.
	const elmScript = importScript(
		`//www.googletagmanager.com/gtag/js?id=${trackingID}`,
		callback
	);
	
	if(canCreateComment) {
		const commentTag = document.createComment(" Global site tag (gtag.js) - Google Analytics ");
		elmScript.parentNode.insertBefore(commentTag, elmScript);
	}
	
	return gtag;
}

loadGoogleAnalytics._version = version;

export default loadGoogleAnalytics;
