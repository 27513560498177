// [for History API]
import u_ from "@pressmedia/u_";
import csl from "../csl/index";

export default {
	// history.pushStateが使用可能かどうか
	enablePushState: (history && history.pushState && history.state !== undefined) ? true : false,
	
	/**
	 * 履歴の追加
	 * @param {Object} [dataObj={}]: state object
	 * @param {String} [url=""]
	 * @returns this
	 */
	pushState: function pushState(dataObj, url) {
		this.enablePushState && this._changeHistoryState("pushState", dataObj, url);
		return this;
	},
	
	/**
	 * URLの書き換え
	 * @param {Object} [dataObj={}]: state object
	 * @param {String} [url=""]
	 * @returns this
	 */
	replaceState: function replaceState(dataObj, url) {
		this.enablePushState && this._changeHistoryState("replaceState", dataObj, url);
		return this;
	},
	
	/**
	 * historyAPIの実行
	 * @param {String} method: "pushState" | "replaceState"
	 * @param {Object} [dataObj={}]: state object
	 * @param {String} [url=""]
	 * @returns this
	 */
	_changeHistoryState: function _changeHistoryState(method, dataObj, url) {
		let urlChange;
		
		try {
			u_.isObject(dataObj) || (dataObj = {});
			u_.isString(url) || (url = undefined);
			urlChange = !!url;
			
			if(urlChange) {
				this._GET = (url.indexOf("?") < 0) ? {} : u_.parseQueryString(url);
				
			} else {
				this._GET = Object.assign({}, dataObj);
				url = this.URL + u_.createQueryString(this._GET);
				!!location.hash && (url += location.hash);
			}
			
			history[method](Object.assign({}, dataObj), this.name, url);
			
		} catch(e) {
			csl.warn("history." + method, e);
			return false;
		}
		
		urlChange && this.setUrl();
		
		csl.log.blue(method, this.currentPath);
		return this;
	}
};
