// [for scroll]
import u_ from "@pressmedia/u_";
import csl from "../csl/index";

export default {
	_scrollTop: 0,
	
	/**
	 * 現在のスクロール位置のY座標を保存
	 * @param {Number} [offset=window.pageYOffse]
	 * @returns this
	 */
	saveScroll: function saveScroll(offset) {
		u_.isNumber(offset) || (offset = window.pageYOffset);
		this._scrollTop = offset;
		return this;
	},
	
	/**
	 * 最後に実行したsaveScrollの座標に戻す
	 * @returns this
	 */
	loadScroll: function loadScroll() {
		window.scrollTo(window.pageXOffset, this._scrollTop);
		return this;
	},
	
	/**
	 * スクロールのY座標を更新
	 * @param {Number} offset
	 * @returns this
	 */
	setScroll: function setScroll(offset) {
		if( u_.isNumber(offset) ) {
			window.scrollTo(window.pageXOffset, offset);
		} else {
			csl.warn(offset, "isn't Number.");
		}
		return this;
	}
};
