import { Klass, u_ } from "@pressmedia/webappbase";
import $ from "jquery";

/**
 * アラート用コンテンツを追加
 * @returns this
 */
export default function addAlert() {
	const elm = `<div>
		<div class="msg"></div>
		<div class="alert-btns">
			<a class="alert-btn btn-ok" href="./"><span>OK</span></a>
			<a class="alert-btn btn-cancel" href="./"><span>キャンセル</span></a>
		</div>
	</div>`;
	this.addContent("alert", elm);
	
	const WAB = Klass("WebAppBase").prototype;
	WAB.confirm = confirm;
	WAB.alert = alert;
}

/**
 * 確認表示
 * @param {Strng} msg: 表示メッセージ
 * @param {Function} cbTrue: OKボタン押下時のコールバック関数
 * @param {Function} cbFalse: キャンセルボタン押下時のコールバック関数
 * @param {Boolean} [flgShowFalse=true]: キャンセルボタンの表示ON / OFF
 * @returns $.Deferred().promise()
 */
function confirm(msg, cbTrue, cbFalse, flgShowFalse) {
	u_.isString(msg) || (msg = "");
	u_.isBoolean(flgShowFalse) || (flgShowFalse = true);
	
	const
		ppup = this.popup,
		$alert = ppup.getContents("alert");
	
	return $.Deferred(df => {
		// メッセージをセット
		$alert.children(".msg").html(msg);
		
		//ボタン押下時のコールバックをセット
		$alert
		.on("click.confirm", ".btn-ok", function(e) {
			e.preventDefault();
			ppup.hideContents( "alert", df.resolve.bind(df) );
		})
		.on("click.confirm", ".btn-cancel", function(e) {
			e.preventDefault();
			ppup.hideContents( "alert", df.reject.bind(df) );
		});
		
		if(flgShowFalse) {
			$alert.find(".btn-cancel").show();
		} else {
			$alert.find(".btn-cancel").hide();
		}
		
		// メッセージを表示
		ppup.showContents("alert", function() {
			$alert.find(".btn-ok").trigger("focus");
			$alert.scrollTop(0);
		});
		
		return df.promise();
	}).then(
		u_.isFunction(cbTrue) && cbTrue,
		u_.isFunction(cbFalse) && cbFalse
	).always(() => {
		$alert.off(".confirm");
	});
}

/**
 * アラート表示
 * @param {Strng} msg: 表示メッセージ
 * @param {Function} callback: OKボタン押下時のコールバック関数
 * @returns  $.Deferred().promise()
 */
function alert(msg, callback) {
	return this.confirm(msg, callback, null, false);
}
