const version = "2.0.0";

import importScript from "./importScript";

function loadGoogleTagManager(gtmID, callback) {
	if(!gtmID || typeof gtmID !== "string") {
		return false;
	}
	
	// タグの読み込みが完了するまで使用されるものを定義
	Array.isArray(window.dataLayer) || (window.dataLayer = []);
	window.dataLayer.push({
		"gtm.start": new Date().getTime(),
		"event": "gtm.js"
	});
	
	// 読み込み用スクリプトの挿入
	const canCreateComment = (typeof document.createComment === "function");
	
	let elmTarget = document.getElementsByTagName("script")[0];
	
	if(canCreateComment) {
		const commentTag = document.createComment(" End Google Tag Manager ");
		elmTarget.parentNode.insertBefore(commentTag, elmTarget);
		elmTarget = commentTag;
	}
	
	// create script & insert DOM tree.
	const elmScript = importScript(
		`//www.googletagmanager.com/gtm.js?id=${gtmID}`,
		elmTarget,
		callback
	);
	
	if(canCreateComment) {
		elmTarget = elmScript;
		const commentTag = document.createComment(" Google Tag Manager ");
		elmScript.parentNode.insertBefore(commentTag, elmScript);
	}
	
	return true;
}

loadGoogleTagManager._version = version;

export default loadGoogleTagManager;
