import u_ from "@pressmedia/u_";

// **** define Core ****
function Core() {}

// [Core prototype] extends u_.
Object.assign(Core.prototype, u_);


// **** define Klass ****
function Klass(klassName) {
	if(klassName === undefined) {
		return Object.assign({}, Klass._klassMap);
	} else
	if( !u_.isString(klassName) ) {
		throw new TypeError("arguments[0] must be string.");
	}
	
	return Klass._klassMap[klassName];
}

/**
 * prototypeの継承用関数
 * (via. http://qiita.com/LightSpeedC/items/d307d809ecf2710bd957)
 * @param {Function} ctor: コンストラクタ
 * @param {Function} superCtor: 継承元コンストラクタ
 * @returns this
 */
Klass.inherits = function inherits(ctor, superCtor) {
	// check args.
	if(ctor === undefined || ctor === null) {
		throw new TypeError("The constructor to `inherits` must not be null or undefined.");
	}
	if(superCtor === undefined || superCtor === null) {
		throw new TypeError("The super constructor to `inherits` must not be null or undefined.");
	}
	if(superCtor.prototype === undefined) {
		throw new TypeError("The super constructor to `inherits` must have a prototype.");
	}
	
	ctor.super_ = superCtor;
	if(typeof Object.__proto__ === "function") {
		Object.setPrototypeOf(ctor.prototype, superCtor.prototype);
	} else {
		this.goog.inherits(ctor, superCtor);
	}
	return this;
};

// ** [for legacy browser] **
if( !u_.isFunction(Object.__proto__) ) {
	Klass.goog = {	// via. Google Closure
		inherits: function inherits(childCtor, parentCtor) {
			/** @constructor */
			function tempCtor() {}
			tempCtor.prototype = parentCtor.prototype;
			childCtor.superClass_ = parentCtor.prototype;
			childCtor.prototype = new tempCtor();
			/** @override */
			childCtor.prototype.constructor = childCtor;
		}
	};
}

// [作成したClassのリスト領域]
Klass._klassMap = {
	"Klass": Core
};
Core.prototype.klass = "Klass";

/**
 * Klass継承関数
 * ※'extends'はIEで予約語になっているため使用不可
 * @param {Function} KlassConstructor: コンストラクタ
 * @param {String} parentName: 継承元Klass名
 * @param {Object} prop: 追加プロパティ
 * @returns {Function} コンストラクタ
 */
Klass.extends_ = function extends_(KlassConstructor, parentName, prop) {
	if( !u_.isString(parentName) ) {
		throw new TypeError("arguments[1] must be string.");
	}
	
	const Parent = this._klassMap[parentName];
	if(!Parent) {
		throw new Error(`'${parentName}' is undefined.`);
	}
	
	// prototype継承
	this.inherits(KlassConstructor, Parent);
	KlassConstructor.parent = Parent.prototype;
	
	// propで渡された属性値の反映
	u_.isObject(prop) && Object.keys(prop).forEach(k => {
		KlassConstructor.prototype[k] = prop[k];
	});
	
	return KlassConstructor;
};

/**
 * Klass生成関数
 * @param {String} klassName: クラス名
 * @param {Object} prop: プロパティ
 * @returns {Function} コンストラクタ
 */
Klass.create = function create(klassName, prop) {
	if( !u_.isString(klassName) ) {
		throw new TypeError("arguments[0] must be string.");
	}
	if(this._klassMap[klassName]) {
		throw new Error("'" + klassName + "' is already defined.");
	}
	
	u_.isObject(prop) || (prop = {});
	
	const KlassConstructor = function KlassConstructor() {
		this._initialize.apply(this, arguments);
	};
	
	// コンストラクタへextends_関数を登録
	KlassConstructor.extends_ = function extends_(parentName, prop) {
		return Klass.extends_(this, parentName, prop);
	};
	
	this._klassMap[klassName] = KlassConstructor;
	
	if( Object.keys(prop).length ) {
		!prop._initialize && (prop._initialize = function _initialize() {});
		// コアKlassから継承
		this.extends_(KlassConstructor, "Klass", prop);
	}
	
	KlassConstructor.prototype.klass = klassName;
	
	return KlassConstructor;
};

/**
 * インスタンス生成関数
 * ※'new'はIEで予約語になっているため使用不可
 * @param {String} klassName: クラス名
 * @returns {Object} インスタンス
 */
Klass.new_ = function new_(klassName) {
	if( !u_.isString(klassName) ) {
		throw new TypeError("arguments[0] must be string.");
	}
	
	const args = Array.prototype.slice.call(arguments, 0);
	args[0] = this(klassName);
	return new ( Function.prototype.bind.apply(args[0], args) )();
};

export default Klass;
