import { u_ } from "@pressmedia/webappbase";
import DOMReadyPromise from "./DOMReadyPromise";

export default {
	isReady: false,
	showLoading(methodName) {},
	hideLoading(methodName) {},
	
	// [exec関数実行時コールバック（document onready後にコールされます）]
	onExec: null,
	
	// [onReadyで登録された関数の完了コールバック]
	afterExec: null,
	
	/**
	 * exec実行時のコールバックを登録
	 * ※onExec後に並列実行されます
	 * @param {Funciton} fn
	 * @returns this
	 */
	onReady(fn) {
		u_.isFunction(fn) && this._execCallbacks.push(fn);
		return this;
	},
	
	// onReady用配列
	_execCallbacks: [],
	
	/**
	 * アプリケーションの実行
	 * @returns promise
	 */
	async exec() {
		const methodName = "exec";
		let rtn;
		
		try {
			const readyState = await DOMReadyPromise();
			let res = await Promise.all([
				u_.isFunction(this.showLoading) && this.showLoading(methodName)
			]);
			
			// onExecの実行
			res = await Promise.all([
				u_.isFunction(this.onExec) && this.onExec(null, readyState, ...res)
			]);
			
			// onReadyで登録されたコールバックの実行
			res = await Promise.all(
				this._execCallbacks.map(fn => fn.apply(this, res))
			);
			
			this.isReady = true;
			document.body.classList.add("is-ready");
			
			// afterExecの実行
			res = await Promise.all([
				u_.isFunction(this.afterExec) && this.afterExec.apply(this, res)
			]);
			
			rtn = res[0];
			
		} catch(err) {
			console.error(err || `An Error occurred on '${methodName}'`);
			rtn = err;
		}
		
		await Promise.all([
			u_.isFunction(this.hideLoading) && this.hideLoading(methodName)
		]);
		
		return rtn;
	}
};
