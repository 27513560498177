// [for preload images]
import u_ from "@pressmedia/u_";

export default {
	_preloadedImgs: {},
	
	/**
	 * 画像プリロードの実行
	 * @param {String} path: ファイルパス
	 * @param {Function} [cb]: プリロード完了コールバック
	 * @param {Boolean} force: 再読込フラグ
	 * @returns this
	 */
	imgPreload: function imgPreload(path, cb, force) {
		if( !u_.isString(path) ) {
			throw new TypeError("arguments[0] must be string.");
		}
		
		// rootディレクトリへの相対パスを絶対パスへ変更
		path = path.replace(
			( new RegExp( "^" + RegExp.escape(this._relativePath + "/") ) ),
			// originは削除
			this.config.rootpath.replace( ( new RegExp( "^" + RegExp.escape(location.origin) ) ), "" )
		);
		
		// 画像へのパスを分解
		const pathInfo = u_.pathInfo(path);
		
		// 画像格納領域を取得
		if( !u_.isObject(this._preloadedImgs[pathInfo.dirname]) ) {
			this._preloadedImgs[pathInfo.dirname] = {};
		}
		
		// 同一ディレクトリのプリロード済みリストを取得
		const preloadedImgs = this._preloadedImgs[pathInfo.dirname];
		
		if(preloadedImgs[pathInfo.basename] && !force) {
			if( u_.isFunction(cb) ) {
				if( u_.isFunction(preloadedImgs[pathInfo.basename]) ) {
					// プリロード実行中
					preloadedImgs[pathInfo.basename](cb);
					
				} else {
					// プリロードが既に完了済
					const img = preloadedImgs[pathInfo.basename];
					cb(null, img.src, img);
				}
			}
			
		} else {
			// プリロード未実行
			preloadedImgs[pathInfo.basename] = bookCB => {
				preloadedImgs[pathInfo.basename].callbacks.push(bookCB);
			};
			preloadedImgs[pathInfo.basename].callbacks = [];
			u_.isFunction(cb) && preloadedImgs[pathInfo.basename](cb);
			
			const img = new Image();
			
			img.onerror = e => {
				preloadedImgs[pathInfo.basename].callbacks.forEach(function(fn) {
					fn(true, e.target.src, e.target, e);
				});
				delete preloadedImgs[pathInfo.basename];
			};
			
			img.onload = e => {
				preloadedImgs[pathInfo.basename].callbacks.forEach(function(fn) {
					fn(null, e.target.src, e.target, e);
				});
				
				preloadedImgs[pathInfo.basename] = img;
			};
			
			img.src = path;
		}
		
		return this;
	},
	
	/**
	 * プリロード済みリストから削除
	 * @param {String} path: ファイル（ディレクトリ）パス
	 * @param {Boolean} isDir: ディレクトリごと削除するか
	 * @returns this
	 */
	clearPreloadedImg: function clearPreloadedImg(path, isDir) {
		if( !u_.isString(path) ) {
			throw new TypeError("arguments[0] must be string.");
		}
		
		if(isDir && !(/\/$/).test(path) ) {
			// ディレクトリ指定の場合でパスの末尾に/がなければ付与する
			path += "/";
		}
		
		// rootディレクトリへの相対パスを絶対パスへ変更
		path = path.replace(
			( new RegExp( "^" + RegExp.escape(this._relativePath + "/") ) ),
			// originは削除
			this.config.rootpath.replace( ( new RegExp( "^" + RegExp.escape(location.origin) ) ), "" )
		);
		
		// 画像へのパスを分解
		const pathInfo = this.pathInfo(path);
		
		if( u_.isObject(this._preloadedImgs[pathInfo.dirname]) ) {
			if(pathInfo.basename && !isDir) {
				if(this._preloadedImgs[pathInfo.dirname][pathInfo.basename]) {
					// プリロード済のデータを削除
					delete this._preloadedImgs[pathInfo.dirname][pathInfo.basename];
				}
			} else {
				// ディレクトリに対応する画像格納領域ごと削除
				delete this._preloadedImgs[pathInfo.dirname];
			}
		}
		
		return this;
	}
};
