import "./RegExp.escape";
import utill from "./utill";
import it from "./isType";
import mb from "./mb";
import qs from "./qs";
import fileInfo from "./fileInfo";
import rfc from "./rfc";
import mapRecursive from "./mapRecursive";

export default Object.assign(it, utill, mb, qs, fileInfo, rfc, mapRecursive, {
	_version: "2.3.0",
});
